@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700&display=swap&subset=latin-ext');

html,
body,
#root {
  height: 100%;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
